import styled from 'styled-components';
import { widths } from 'constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: ${widths.container};
  padding: 0 1.5rem;
  margin: 0 auto;
  z-index: 1;
  flex-grow: ${({ $fill }) => $fill && '1'};
  outline: none !important;
`;

export default Container;
