import widths from './widths';

const breakpoints = {
  xxs: '325px',
  xs: '372px',
  sm: '500px',
  s: '500px',
  m: '750px',
  l: '992px',
  xl: '1200px',
  xxl: widths.container,
  height: '480px'
};

export default breakpoints;
