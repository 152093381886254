import React from 'react';
import PropTypes from 'prop-types';
import HeartSpinner from 'features/heart-spinner/HeartSpinner';
import FlowerBorder from 'features/flower-border/FlowerBorder';
import * as S from './LoadingPage.styles';

const LoadingPage = ({ text, offset }) => (
  <S.Container>
    <FlowerBorder />
    <HeartSpinner text={text} offset={offset} />
  </S.Container>
);

LoadingPage.defaultProps = {
  text: 'Loading...',
  offset: '0px'
};

LoadingPage.propTypes = {
  text: PropTypes.string,
  offset: PropTypes.string
};

export default LoadingPage;
