import React, { lazy, Suspense, Fragment } from 'react';
import Container from 'features/containers/Container.styles';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import LoadingPage from 'pages/loading-page/LoadingPage';
import { Slide } from 'react-toastify';
import Toast from 'features/toast/Toast';
import { CSSReset } from './App.styles';
import '@fontsource/sniglet';

const NotFoundPage = lazy(() => import('pages/not-found-page/NotFoundPage'));
const GiftsSearchPage = lazy(() => import('pages/gifts-search-page/GiftsSearchPage'));
const AlwaysAndForeverOurSecretsPage = lazy(() => import('pages/gifts/always-and-forever-our-secrets-page/AlwaysAndForeverOurSecretsPage'));
const Happy19thBirthdayPage = lazy(() => import('pages/gifts/happy-19th-birthday-page/Happy19thBirthdayPage'));
const TeasingAndLotsOfKissingPage = lazy(() => import('pages/gifts/teasing-and-lots-of-kissing-page/TeasingAndLotsOfKissingPage'));
const WhatAreMyQualitiesPage = lazy(() => import('pages/gifts/what-are-my-qualities-page/WhatAreMyQualitiesPage'));
const AbTumSirfMeriHoPage = lazy(() => import('pages/gifts/ab-tum-sirf-meri-ho-page/AbTumSirfMeriHoPage'));
const YummyChecklistPage = lazy(() => import('pages/gifts/yummy-checklist-page/YummyChecklistPage'));

const App = () => (
  <Fragment>
    <CSSReset />

    <Container $fill tabIndex="0" autoFocus>
      <Suspense fallback={<LoadingPage offset="5px" />}>
        <Routes>
          <Route path="/" element={<Outlet />}>
            <Route path="*" element={<NotFoundPage />} />
            <Route index element={<Navigate to="/gifts" replace />} />

            <Route path="gifts">
              <Route index element={<GiftsSearchPage />} />
              <Route path="always-and-forever-our-secrets" element={<AlwaysAndForeverOurSecretsPage />} />
              <Route path="happy-19th-birthday" element={<Happy19thBirthdayPage />} />
              <Route path="happy-nineteenth-birthday" element={<Navigate to="/gifts/happy-19th-birthday" replace />} />
              <Route path="teasing-and-lots-of-kissing" element={<TeasingAndLotsOfKissingPage />} />
              <Route path="what-are-my-qualities" element={<WhatAreMyQualitiesPage />} />
              <Route path="ab-tum-sirf-meri-ho" element={<AbTumSirfMeriHoPage />} />
              <Route path="yummy-checklist" element={<YummyChecklistPage />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </Container>

    <Toast
      position="bottom-left"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      pauseOnHover
      limit={3}
      transition={Slide}
      draggable
      draggableDirection="y"
      draggablePercent={50}
    />
  </Fragment>
);

export default App;
