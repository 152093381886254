import themes from './themes';

export const {
  main: mainTheme,
} = themes;

export { default as themes } from './themes';
export { default as widths } from './widths';
export { default as typography } from './typography';
export { default as breakpoints } from './breakpoints';
