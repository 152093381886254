import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as S from './FlowerBorder.styles';

const FlowerBorder = ({ zIndex, color, topBorder, bottomBorder, topFade, bottomFade }) => (
  <Fragment>
    <S.FlowerTopLeft $zIndex={zIndex} />
    <S.FlowerBottomRight $zIndex={zIndex} />
    <S.FlowerTopRight $zIndex={zIndex} />
    <S.FlowerBottomLeft $zIndex={zIndex} />

    <S.TopBorder
      $zIndex={zIndex}
      $color={color}
      $topBorder={topBorder}
      $topFade={topFade}
    />
    <S.BottomBorder
      $zIndex={zIndex}
      $color={color}
      $bottomBorder={bottomBorder}
      $bottomFade={bottomFade}
    />
  </Fragment>
);

FlowerBorder.defaultProps = {
  zIndex: '-1',
  color: null,
  topBorder: '0vh',
  bottomBorder: '0vh',
  topFade: '0%',
  bottomFade: '0%'
};

FlowerBorder.propTypes = {
  zIndex: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  color: PropTypes.string,
  topBorder: PropTypes.string,
  bottomBorder: PropTypes.string,
  topFade: PropTypes.string,
  bottomFade: PropTypes.string,
};

export default FlowerBorder;
