import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import './toast.css';
import styles from './toast.module.css';

const Toast = ({ ...props }) => (
  <ToastContainer
    {...props}
    toastClassName={styles.toast}
  />
);

export default Toast;
