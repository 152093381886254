import styled from 'styled-components';
import noSelectMixin from 'features/mixins/noSelect.mixin';

const heartSpinnerImage = `${process.env.PUBLIC_URL}/assets/heart-spinner-sm.gif`;

export const Container = styled.div`
  display: grid;
  place-items: center;
`;

export const HeartSpinner = styled.img.attrs((props) => ({
  ...props,
  src: heartSpinnerImage,
  draggable: false
}))`
  ${noSelectMixin};
`;

export const Text = styled.p`
  position: relative;
  left: ${({ $offset }) => $offset || '0px'};
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  color: #9d0909;
  ${noSelectMixin};
  margin-top: -1rem;
`;
