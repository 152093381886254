const themes = {
  main: {
    primary: '#6b0313',
    secondary: '#fffdf7',
    tertiary: '#f7f4e9',
    darkShaded: '#222222',
    dark: '#333333',
    mediumShaded: '#555555',
    medium: '#757575',
    lightShaded: '#d6d6d6',
    light: '#e6e6e6',
    danger: '#c62f2f',
    warning: '#f7a510',
    success: '#4cb240'
  }
};

export default themes;
