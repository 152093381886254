import React from 'react';
import PropTypes from 'prop-types';
import * as S from './HeartSpinner.styles';

const HeartSpinner = ({ text, alt, size, offset }) => (
  <S.Container>
    <S.HeartSpinner
      alt={alt}
      width={size}
    />
    <S.Text $offset={offset}>{text}</S.Text>
  </S.Container>
);

HeartSpinner.defaultProps = {
  text: 'Loading...',
  alt: '',
  size: '200px',
  offset: '0px'
};

HeartSpinner.propTypes = {
  text: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  offset: PropTypes.string
};

export default HeartSpinner;
