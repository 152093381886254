import styled, { css } from 'styled-components';
import { breakpoints, mainTheme as theme } from 'constants';
import noSelectMixin from 'features/mixins/noSelect.mixin';

const flowersImage = `${process.env.PUBLIC_URL}/assets/flowers.png`;

const globalFlowerCSS = css`
  position: fixed;
  z-index: ${({ $zIndex }) => $zIndex ?? '-1'};
  ${noSelectMixin};
  pointer-events: none;
`;

export const FlowerTopLeft = styled.img.attrs((props) => ({
  ...props,
  src: flowersImage,
  draggable: false
}))`
  ${globalFlowerCSS};
  top: -300px;
  left: -250px;

  @media screen and (max-width: 425px) {
    top: -250px;
    left: -200px;
  }

  @media screen and (max-width: 375px) {
    top: -225px;
    left: -175px;
  }

  @media screen and (max-width: 325px) {
    top: -200px;
    left: -150px;
  }

  @media screen and (max-height: ${breakpoints.height}) {
    top: -350px;
    left: -300px;
  }
`;

export const FlowerBottomRight = styled.img.attrs((props) => ({
  ...props,
  src: flowersImage,
  draggable: false
}))`
  ${globalFlowerCSS};
  bottom: -300px;
  right: -250px;
  transform: scale(-1, -1);

  @media screen and (max-width: 425px) {
    bottom: -250px;
    right: -200px;
  }

  @media screen and (max-width: 375px) {
    bottom: -225px;
    right: -175px;
  }

  @media screen and (max-width: 325px) {
    bottom: -200px;
    right: -150px;
  }

  @media screen and (max-height: ${breakpoints.height}) {
    bottom: -350px;
    right: -300px;
  }
`;

export const FlowerTopRight = styled.img.attrs((props) => ({
  ...props,
  src: flowersImage,
  draggable: false
}))`
  ${globalFlowerCSS};
  top: -300px;
  right: -250px;
  transform: scale(-1, 1);

  @media screen and (max-width: 425px) {
    top: -250px;
    right: -200px;
  }

  @media screen and (max-width: 375px) {
    top: -225px;
    right: -175px;
  }

  @media screen and (max-width: 325px) {
    top: -200px;
    right: -150px;
  }

  @media screen and (max-height: ${breakpoints.height}) {
    top: -350px;
    right: -300px;
  }
`;

export const FlowerBottomLeft = styled.img.attrs((props) => ({
  ...props,
  src: flowersImage,
  draggable: false
}))`
  ${globalFlowerCSS};
  bottom: -300px;
  left: -250px;
  transform: scale(1, -1);

  @media screen and (max-width: 425px) {
    bottom: -250px;
    left: -200px;
  }

  @media screen and (max-width: 375px) {
    bottom: -225px;
    left: -175px;
  }

  @media screen and (max-width: 325px) {
    bottom: -200px;
    left: -150px;
  }

  @media screen and (max-height: ${breakpoints.height}) {
    bottom: -350px;
    left: -300px;
  }
`;

const globalBorderCSS = css`
  position: fixed;
  width: 100%;
  z-index: ${({ $zIndex }) => $zIndex - 1 ?? '-2'};
  pointer-events: none;
`;

export const TopBorder = styled.div`
  ${globalBorderCSS};
  top: 0;
  height: ${({ $topBorder }) => $topBorder || '0vh'};
  background: ${({ $color, $topFade }) => (
    `linear-gradient(to top, rgba(255, 255, 255, 0) 0, ${$color ?? theme.secondary} ${$topFade ?? '0%'})`
  )};
`;

export const BottomBorder = styled.div`
  ${globalBorderCSS};
  bottom: 0;
  height: ${({ $bottomBorder }) => $bottomBorder || '0vh'};
  background: ${({ $color, $bottomFade }) => (
    `linear-gradient(rgba(255, 255, 255, 0) 0, ${$color ?? theme.secondary} ${$bottomFade ?? '0%'})`
  )};
`;
